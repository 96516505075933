import React from 'react';
import SEO from "../../../common/SEO";
import Layout from "../../../common/Layout";
import ServiceOne from "../../service/ServiceOne";
import ServiceTwo from "../../service/ServiceTwo";
import ServiceThree from "../../service/ServiceThree";
import ServiceFour from "../../service/ServiceFour";
import ServiceFive from "../../service/ServiceFive";
import SectionTitle from "../../sectionTitle/SectionTitle";
import AdvanceTabOneCarbonWatch from "./AdvanceTabOneCarbonWatch";
import AdvanceTabTwoCarbonWatch from "./AdvanceTabTwoCarbonWatch";
import AdvanceTabThreeCarbonWatch from "./AdvanceTabThreeCarbonWatch";
import Separator from "../../separator/Separator";


const AdvanceTabProductCarbonWatch = () => {
    return (
        <>
            <SEO title="Carbon Watch Monitoring || 360Metrics Monitoring Solution" />
            <Layout>
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Carbon watch monitoring."
                                    title = "Monitor your cabon emissions. <br />Reporting and detail analysis."
                                    description = ""
                                    />
                            </div>
                        </div>
                        {/*<ServiceThree 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                        />*/}
                    </div>
                </div>
                {/* End Service Area  */}


                <div className="main-content">

                    
                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Comprehensive dashboards"
                                        title = "Your datas, your dashboards."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceTabOneCarbonWatch />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />
                    
                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Scalability and performance"
                                        title = "Kubernetes under the hood."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceTabTwoCarbonWatch />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    
                </div>
            </Layout>
        </>
    )
}
export default AdvanceTabProductCarbonWatch;


