import React, {useState}  from 'react';
import axios from "axios"
import {Link} from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];
const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);
const indexFourLink = (footerIntemFour.quicklink);

const FooterOne = () => {
    
const [fields, setFields] = useState({});
const [errors, setErrors] = useState({});
const [success, setSuccess] = useState('');

const handleValidation = () => {
      const formFields = {...fields};
      const formErrors = {};
      let formIsValid = true;
  
      //Name
      if(!formFields["name"]){
        formIsValid = false;
        formErrors["name"] = "Enter your name";
      }
  
      if(typeof formFields["name"] !== "undefined"){
        if(!formFields["name"].match(/^[a-zA-Z]+$/)){
          formIsValid = false;
          formErrors["name"] = "Only letters";
        }       
      }
  
      //Email
      if(!formFields["email"]){
        formIsValid = false;
        formErrors["email"] = "Enter your email.";
      }
  
      if(typeof formFields["email"] !== "undefined"){
        let lastAtPos = formFields["email"].lastIndexOf('@');
        let lastDotPos = formFields["email"].lastIndexOf('.');
  
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formFields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          formFields["email"] = "Email is not valid";
        }
      }     

      setErrors(formErrors)
      setSuccess('');
      return formIsValid;
    }
    
const handleChange = (field, value) => {
      setFields({
        ...fields,
        [field]: value
      })
    }
const handleSubmit = e => {
    // Prevent the default submit and page reload
    e.preventDefault()

    // Handle validations
     if (handleValidation()){ 

        //Post to subscribe on Listmonk
        axios.post('https://www.360metrics.io/api/subscribe', {
        email: fields["email"].toLowerCase(),
        name: fields["name"].toLowerCase(),
        status: 'enabled',
        lists: [3],
        }).then((response) => {
        // Success
        setSuccess('Subscription done!');
        handleChange('email','');
    })
    .catch((error) => {
        setSuccess('Backend Error!');
      });
    }
}
  
    return (
        <>
            <footer className="rn-footer footer-style-default">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="logo">
                                        <Logo 
                                            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                        />
                                    </div>
                                    <h3 className="text-big">{footerIntem.title}</h3>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemOne.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexOneLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="widget-menu-bottom">
                                        <h4 className="title">{footerIntemTwo.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexTwoLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemThree.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexThreeLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget-menu-bottom">
                                        <h4 className="title">{footerIntemFour.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexFourLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{footerIntemFive.subtitle}</h6>
                                        <form className="newsletter-form" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="userName"
                                                    id="userName"
                                                    required="true"
                                                    placeholder="Enter Your Name Here"
                                                    onChange={e => handleChange('name', e.target.value)} value={fields["name"]}
                                                />      
                                                <span className="error">{errors["name"]}</span>
                                                <br></br>                                          
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter Your Email Here"
                                                    onChange={e => handleChange('email', e.target.value)} value= {fields["email"]}
                                                />
                                                    <span className="error">{errors["email"]}</span>                                     
                                            </div>
                                            <div className="form-group">
                                                <button className="btn-default" type="submit">Register</button>
                                                <div>     
                                                    <span className="success">{success}</span>  
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterOne
