import React from 'react';
import SEO from "../../../common/SEO";
import Layout from "../../../common/Layout";
import ServiceOne from '../../service/ServiceOne';
import SectionTitle from "../../sectionTitle/SectionTitle";
import AdvanceTabOneOmni from "./AdvanceTabOneOmni";
import AdvanceTabTwoOmni from "./AdvanceTabTwoOmni";
import AdvanceTabThreeOmni from "./AdvanceTabThreeOmni";
import Separator from "../../separator/Separator";


const AdvanceTabProductOmni = () => {
    return (
        <>
            <SEO title="OMNI Monitoring || 360Metrics Monitoring Solution" />
            <Layout>
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Scalable SNMP monitoring and availability."
                                    title = "OMNI Monitoring. <br />Powerful, scalable and fast."
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}


                <div className="main-content">

                    
                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Comprehensive dashboards"
                                        title = "Matching your business."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceTabOneOmni />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />
                    
                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Scalability and performance"
                                        title = "Kubernetes under the hood."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceTabTwoOmni />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    
                </div>
            </Layout>
        </>
    )
}
export default AdvanceTabProductOmni;


