import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 45.50145415978464,
      lng: -73.56725115698643
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      // API key restricted to Ben's HOME IP address !! on his google account
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAGOODiIewwqI_VIbabT1L8vYLGaTDyZ5o" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={45.50145415978464}
            lng={-73.56725115698643}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;