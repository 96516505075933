import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

const Data = [
    {
        countNum : 2024,
        countTitle: 'Q1 - Product design.',
        description: 'Define prime features and adjust with markets needs. ',
    },
    {
        countNum : 2024,
        countTitle: 'Q2 & Q3 - Prototyping & Validating',
        description: 'Present our two main products OMNI & CarbonWatch to our long terms partners and clients.',

    },
    {
        countNum : 2024,
        countTitle: 'Q4 - Product launch',
        description: 'Official public release of our produts.',
    },
];
const CounterUpTwo = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="title">{data.countTitle}</h5>
                        <p className="description">{data.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpTwo;