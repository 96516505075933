import React from 'react'
import SEO from "../common/SEO";
/* import HeaderTopNews from '../common/header/HeaderTopNews'; */
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamOne from "../elements/team/TeamOne";
import TeamTwo from "../elements/team/TeamTwo";
import TeamThree from "../elements/team/TeamThree";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                {/*<HeaderTopNews />*/}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/bg-about-us.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">We are 360Metrics.</span></h3>
                                    </div>
                                    <h1 className="title display-one">We build monitoring software <br />  that scales</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Six co-founders, with experience in the monitoring industry since 2008.<br></br><br></br>In 2023 we launched 360Metrics.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10"><b>360Metrics</b> was established with the aim of providing customers with a scalable monitoring solution for their extensive IT infrastructures.<br/><br/>

The six individuals who founded the company bring 15 years of experience in the field of monitoring, having worked with various products. Based on their market expertise, they identified a gap in the industry and recognized the need for a robust monitoring tool.<br/><br/>

Given the inadequacies in technological coverage within the industry, particularly concerning SNMP (Simple Network Management Protocol), we selected it as our initial target. Recognizing its maturity, we opted for a scalable and modern solution, utilizing a blend of well-known open-source tools and in-house development.<br/><br/>

Our overarching objective is to create a solution that can be expanded to incorporate other data sources, including REST APIs, CLIs, and more. This extension can be directed towards focusing on the IT infrastructure itself or delving into power consumption data, crucial for regulatory reporting in regions such as the European Union, the United States, and beyond.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Brand Area  
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "In a nutshell"
                                    title = "Mission & Vision"
                                    description = "<b>Mission</b>: Replace legacy monitoring solutions with a simple, highly scalable, modern and efficient solution.<br>   
<b>Vision</b>: Develop and offer the most scalable monitoring and reporting solutions for fault detection, capacity<br> and performance management, and sensitive data collection for large infrastructures."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <AboutFour image="./images/about/contact-image.jpg" />
                
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
